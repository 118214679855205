<template>
  <section class="section srv-catalog">
    <h2>Каталог услуг</h2>
    <div class="srv-catalog__container">
      <div class="srv-catalog__content">
        <form class="filter__form services__form" autocomplete="off">
          <div class="row search">
            <div class="col-12 search__input">
              <div class="search__input__wrap">
                <input
                  v-model="form.title.value"
                  id="news_search"
                  type="text"
                  placeholder="Введите название услуги для интерактивного поиска"
                  maxlength="255"
                />
                <button v-if="form.title.value" type="button" @click="resetSearchInput">
                  <CloseIcon />
                </button>
                <div v-if="form.title.value" class="search__result-dropdown">
                  <ul v-if="searchResults && searchResults.length">
                    <li v-for="(item, index) in searchResults" :key="index" class="search__result-item">
                      <router-link :to="{ name: 'service', params: { id: item.id } }">
                        {{ item.title }}
                      </router-link>
                    </li>
                  </ul>
                  <p v-else>Ничего не найдено</p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          class="srv-catalog__categories"
          :class="{ 'srv-catalog__categories--active': childCategories && childCategories.length }"
          v-if="servicesCategories && servicesCategories.length"
        >
          <div class="srv-catalog__categories-container">
            <div class="srv-catalog__wrap-container srv-catalog__wrap-container--parent">
              <ul class="srv-catalog__wrap">
                <li v-for="(item, index) in servicesCategories" :key="index">
                  <a
                    @click.prevent="showChildCategories(item.id)"
                    :class="{ active: isCategoryActive(item.id, true) }"
                    class="srv-catalog__item link"
                    href="#"
                  >
                    <component :is="item.icon" class="srv-catalog__icon" v-if="item.id === -1" />
                    <i class="srv-catalog__icon" v-else-if="item.icon" v-html="item.icon"></i>
                    <span>{{ item.title }}</span>
                    <i class="icon__right">
                      <ArrowRightIcon />
                    </i>
                  </a>
                </li>
              </ul>
              <Multiselect
                track-by="id"
                label="title"
                class="srv-catalog__ms"
                :value="selectedParentCategory"
                :options="servicesCategories"
                @input="showChildCategories($event.id)"
                placeholder="Выберите из списка"
                selectLabel="Выбрать ↵"
                deselectLabel="Удалить ↵"
                selectedLabel="Выбрано"
                :searchable="true"
                :allow-empty="false"
              >
                <div class="srv-catalog__ms-label" slot="singleLabel" v-if="selectedParentCategory">
                  <component
                    :is="selectedParentCategory.icon"
                    class="srv-catalog__icon"
                    v-if="selectedParentCategory.id === -1"
                  />
                  <i
                    class="srv-catalog__ms-icon"
                    v-else-if="selectedParentCategory.icon"
                    v-html="selectedParentCategory.icon"
                  ></i>
                  <span class="srv-catalog__ms-title">{{ selectedParentCategory.title }}</span>
                </div>
                <span slot="noOptions">Список пуст</span>
              </Multiselect>
            </div>
            <div
              class="srv-catalog__wrap-container srv-catalog__wrap-container--child"
              v-if="childCategories && childCategories.length"
            >
              <ul class="srv-catalog__wrap">
                <li v-for="(item, index) in childCategories" :key="index">
                  <a
                    @click.prevent="showServices(item.id)"
                    :class="{ active: isCategoryActive(item.id, false) }"
                    class="srv-catalog__item link"
                    href=""
                  >
                    <span class="srv-catalog__title">{{ item.title }}</span>
                    <ArrowRightIcon />
                  </a>
                </li>
              </ul>
              <Multiselect
                track-by="id"
                label="title"
                class="srv-catalog__ms"
                :value="selectedChildCategory"
                :options="childCategories"
                @input="showServices($event.id)"
                v-if="childCategories && childCategories.length"
                placeholder="Выберите из списка"
                selectLabel="Выбрать ↵"
                deselectLabel="Удалить ↵"
                selectedLabel="Выбрано"
                :searchable="true"
                :allow-empty="false"
              >
                <div class="srv-catalog__ms-label" slot="singleLabel" v-if="selectedChildCategory">
                  <i
                    class="srv-catalog__ms-icon"
                    v-if="selectedChildCategory.icon"
                    v-html="selectedChildCategory.icon"
                  ></i>
                  <span class="srv-catalog__ms-title">{{ selectedChildCategory.title }}</span>
                </div>
                <span slot="noOptions">Список пуст</span>
              </Multiselect>
            </div>
          </div>
          <ul class="srv-catalog__wrap" v-if="selectedServices && selectedServices.length">
            <li v-for="(item, index) in selectedServices" :key="index">
              <router-link :to="{ name: 'service', params: { id: item.id } }" class="srv-catalog__link link">
                <div class="srv-catalog__type" v-if="item.is_electronic_service">Электронная услуга</div>
                <div class="srv-catalog__title">{{ item.subtitle }}</div>
              </router-link>
            </li>
          </ul>
        </div>
        <p v-else class="_item__text">Услуг нет</p>
      </div>
      <Banners />
    </div>
  </section>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowRightIcon from "@/components/svg/ArrowRightSmall.vue";
import StarIcon from "@/components/svg/StarIcon.vue";
import Banners from "@/views/services/components/Banners.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "ServicesCategoriesComponent",
  data() {
    return {
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
      },
      selectedParentCategory: null,
      selectedChildCategory: null,
      childCategories: [],
      selectedServices: [],
    };
  },
  mounted() {
    this.showChildCategories(-1);
  },
  computed: {
    /**
     * Возвращать только категории с услугами
     */
    servicesCategories() {
      let servicesCategories = this.$store.state.services_page?.category_services.filter(
        (item) =>
          (item.services && item.services.length) ||
          (item.child_categories &&
            item.child_categories.length &&
            item.child_categories.filter((i) => i.services && i.services.length).length)
      );
      let popular = [];
      if (this.popularServices && this.popularServices.length) {
        popular = [
          {
            id: -1,
            title: "Популярное",
            icon: StarIcon,
          },
        ];
      }
      servicesCategories = popular.concat(servicesCategories);
      return servicesCategories;
    },
    /**
     * Все популярные услуги
     */
    popularServices() {
      return this.$store.state.services_page?.all_services.filter((item) => {
        return item.is_popular;
      });
    },
    /**
     * Все популярные услуги привязанные к дочерним категориям (популярное в разделе)
     */
    popularChildServices() {
      if (this.selectedParentCategory) {
        let popularServicesArr = [];
        this.selectedParentCategory.child_categories.forEach((category) => {
          popularServicesArr = popularServicesArr.concat(category.services.filter((item) => item.is_popular));
        });
        return popularServicesArr;
      }
      return [];
    },
    /**
     * Поиск
     */
    searchResults() {
      if (this.form.title.value) {
        return this.$store.state.services_page?.all_services.filter((item) => {
          return item.title.toLowerCase().indexOf(this.form.title.value.toLowerCase()) !== -1;
        });
      }
      return [];
    },
  },
  methods: {
    showChildCategories(id) {
      if (id === -1) {
        this.selectedParentCategory = {
          id: -1,
          title: "Популярное",
          icon: StarIcon,
        };
        this.selectedServices = this.popularServices;
        this.childCategories = [];
      } else {
        this.selectedParentCategory = this.servicesCategories.find((item) => item.id === id);
        if (this.selectedParentCategory.services && this.selectedParentCategory.services.length) {
          this.selectedServices = this.selectedParentCategory.services;
          this.childCategories = [];
          this.selectedChildCategory = null;
        } else {
          if (this.popularChildServices && this.popularChildServices.length) {
            this.childCategories = [
              {
                id: -1,
                title: "Популярное в разделе",
              },
            ];
          } else {
            this.childCategories = [];
          }
          this.childCategories = this.childCategories.concat(
            this.selectedParentCategory.child_categories.filter((i) => i.services && i.services.length)
          );
          this.showServices(-1);
        }
      }
    },
    showServices(id) {
      if (id === -1) {
        this.selectedChildCategory = {
          id: -1,
          title: "Популярное в разделе",
        };
        this.selectedServices = this.popularChildServices;
      } else {
        this.selectedChildCategory = this.childCategories.find((item) => item.id === id);
        if (this.selectedChildCategory.services) {
          this.selectedServices = this.selectedChildCategory.services;
        }
      }
    },
    isCategoryActive(id, isParent) {
      if (isParent && this.selectedParentCategory) {
        return id === this.selectedParentCategory.id;
      }
      if (!isParent && this.selectedChildCategory) {
        return id === this.selectedChildCategory.id;
      }
      return false;
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
  components: {
    CloseIcon,
    ArrowRightIcon,
    StarIcon,
    Multiselect,
    Banners,
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
