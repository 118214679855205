<template>
  <i class="icon icon__right">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8302 11.2902L10.5902 7.05019C10.4972 6.95646 10.3866 6.88207 10.2648 6.8313C10.1429 6.78053 10.0122 6.75439 9.88019 6.75439C9.74818 6.75439 9.61747 6.78053 9.49561 6.8313C9.37375 6.88207 9.26315 6.95646 9.17019 7.05019C8.98394 7.23756 8.87939 7.49101 8.87939 7.75519C8.87939 8.01938 8.98394 8.27283 9.17019 8.46019L12.7102 12.0002L9.17019 15.5402C8.98394 15.7276 8.87939 15.981 8.87939 16.2452C8.87939 16.5094 8.98394 16.7628 9.17019 16.9502C9.26363 17.0429 9.37444 17.1162 9.49628 17.166C9.61812 17.2157 9.74858 17.241 9.88019 17.2402C10.0118 17.241 10.1423 17.2157 10.2641 17.166C10.3859 17.1162 10.4967 17.0429 10.5902 16.9502L14.8302 12.7102C14.9239 12.6172 14.9983 12.5066 15.0491 12.3848C15.0998 12.2629 15.126 12.1322 15.126 12.0002C15.126 11.8682 15.0998 11.7375 15.0491 11.6156C14.9983 11.4938 14.9239 11.3832 14.8302 11.2902Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "ArrowRight",
};
</script>
