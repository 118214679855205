<template>
  <div class="srv-banner__container" v-if="banners && banners.length">
    <div class="srv-banner">
      <a
        :href="item.link"
        target="_blank"
        v-for="(item, index) in banners"
        :key="index"
        class="srv-banner__item"
      >
        <img
          class="srv-banner__image"
          :src="item.head_img | image($store.state.api)"
          :alt="item.head_img | image_alt"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceBannersSituations",
  computed: {
    banners() {
      return this.$store.state?.banners.data;
    },
  },
};
</script>

<style lang="stylus">
.srv-banner {
  display grid
  grid-gap var(--margin)
  +below(1440px) {
    grid-template-columns repeat(3, 1fr)
  }
  &__container {
    overflow: auto;
  }

  &__item {
    width 100%
    display inline-flex
    overflow hidden
    border-radius var(--radius)
    +below(768px) {
      width 250px
    }

    &:after {
      content: "";
      display: block;
      absolute left top
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--color_black_o15);
      z-index: 1;
      opacity: 0;
      transition: .3s;
    }

    &:hover {
      &:after {
        opacity: 1;
      }

      & ^[0]__image {
        transform: scale(1.08);
      }
    }
  }

  &__image {
    width 100%
    height auto
    object-fit cover
    object-position center
    transition .2s
  }
}
</style>
