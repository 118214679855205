<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <ServicesCategories />
        <ServiceLifeSituations />
      </div>
    </div>
  </main>
</template>

<script>
import ServiceLifeSituations from "@/views/services/services_catalog/components/ServiceLifeSituations.vue";
import ServicesCategories from "@/views/services/services_catalog/components/ServicesCategories.vue";

export default {
  name: "ServicesCatalogPage",
  async asyncData({ store }) {
    await store.dispatch("GET_SERVICES_PAGE");
  },
  components: {
    ServicesCategories,
    ServiceLifeSituations,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/services/index.styl"
</style>
