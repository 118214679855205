<template>
  <section
    class="section services__life-situations section-pb48"
    v-if="lifeSituations && lifeSituations.length"
  >
    <h2>Жизненные ситуации</h2>
    <div class="row">
      <router-link
        :to="{
          name: 'service',
          params: { id: item.id },
        }"
        v-for="(item, index) in lifeSituations"
        :key="index"
        class="col-3 service__life-situation"
      >
        <div class="service__title" v-if="item.subtitle">
          {{ item.subtitle }}
        </div>
        <div class="service__img">
          <div class="img__container">
            <img
              v-if="item.head_img && item.head_img.img && item.head_img.img.url"
              :src="item.head_img | image($store.state.api)"
              :alt="item.head_img | image_alt"
            />
          </div>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "ServiceLifeSituations",
  computed: {
    lifeSituations() {
      return this.$store.state.services_page?.services_life_situation.data;
    },
  },
};
</script>

<style lang="stylus">

@import "~@/styles/mixins/ratio.styl"

.services {
  &__life-situations {
    .service__life-situation {
      display flex
      flex-direction column
      background #F8FBFF
      box-shadow: 5px 6px 12px var(--color_black_o08)
      height auto

      &:hover .service__title {
        color var(--color_blue)
      }
    }

    .service__title {
      flex-basis 80px
      font-weight: 500;
      font-size: 1.375em
      line-height: 28px;
      color: var(--color_dark)
      transition 0.2s
      padding: 24px 24px 0;
      margin-bottom: 8px;
      z-index 2
    }

    .service__img {
      ratio(324, 168)
      z-index 1

      .img__container {
        padding: 0 15px;

        img {
          object-fit contain
          object-position center bottom
        }
      }
    }
  }
}
</style>
